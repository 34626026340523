(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/ui/accordion/assets/javascripts/accordion.js') >= 0) return;  svs.modules.push('/components/ui/accordion/assets/javascripts/accordion.js');

'use strict';

svs.ui = svs.ui || {};
svs.ui.accordion = svs.ui.accordion || {};
svs.ui.Accordion = class Accordion {
  constructor(options) {
    this.log = svs.core.log.getLogger('ui:accordion');
    this.options = options;

    if (options.items && options.items.length && options.el) {
      this.renderItems(options, () => {
        this.initAccordion(options, () => {
          this.setUpListeners(options);
        });
      });
    } else {
      this.initAccordion(options, () => {
        this.setUpListeners(options);
      });
    }
  }
  initAccordion(options, callback) {
    const $el = (options === null || options === void 0 ? void 0 : options.el) || undefined;
    let accordionEl;
    if ($el) {
      if ($el instanceof Element) {
        if ($el.classList.contains('js-accordion')) {
          accordionEl = $el;
        } else {
          accordionEl = $el.querySelector('.js-accordion');
        }
      } else if (document.querySelector(".js-accordion.".concat($el))) {
        accordionEl = document.querySelector(".js-accordion.".concat($el));
      } else {
        accordionEl = document.querySelector(".".concat($el)).querySelector(".js-accordion");
      }
      this.animations = svs.ui.Accordion.AccordionAnimations;
      this.options = {
        accordionEl,
        clickCallback: (options === null || options === void 0 ? void 0 : options.clickCallback) || false,
        branding: (options === null || options === void 0 ? void 0 : options.branding) || false,
        customer: (options === null || options === void 0 ? void 0 : options.customer) || 'neutral',
        allowMultipleOpen: (options === null || options === void 0 ? void 0 : options.allowMultipleOpen) || false,
        items: accordionEl.querySelectorAll('.js-accordion-item'),
        throttled: false
      };
      for (let i = 0; i < this.options.items.length; i++) {
        if (this.options.items[i].getAttribute('aria-expanded') === 'true') {
          this.options.items[i].setAttribute('aria-expanded', 'false');
          this.handleChange(this.options.items[i], null);
        }
      }
      callback();
    } else {
      this.log.error('No el found in options object for Accordion.');
    }
  }

  setUpListeners() {
    const self = this;
    for (let i = 0; i < this.options.items.length; i++) {
      this.options.items[i].classList.add("js-accordion-item-".concat(i));
      this.options.items[i].querySelector('.js-accordion-details').id = this.options.items[i].getAttributeNode('aria-controls').nodeValue;
      this.options.items[i].dataset.index = i;
      this.options.items[i].querySelector('.js-accordion-summary').addEventListener('click', e => {
        self.handleChange(this.options.items[i], e);
      });
      this.options.items[i].addEventListener('keydown', e => {
        self.handleKeyDown(e);
      });
    }
  }

  handleChange(el, evt) {
    const self = this;
    if (!self.options.throttled) {
      let expanded;
      const details = el.querySelector('.js-accordion-details');
      self.options.throttled = true;
      if (el.getAttribute('aria-expanded') === 'false') {
        el.setAttribute('aria-expanded', 'true');
        details.ariaHidden = 'false';
        expanded = true;
      } else {
        el.setAttribute('aria-expanded', 'false');
        details.ariaHidden = 'true';
        expanded = false;
      }
      self.animations.toggleAccordionAnimate(self.options.customer, expanded, el);
      self.animations.handleNeighbors(el, expanded, parseInt(el.dataset.index, 10));

      if (!this.options.allowMultipleOpen) {
        for (let i = 0; i < self.options.items.length; i++) {
          if (self.options.items[i].ariaExpanded !== 'false' && self.options.items[i] !== el) {
            self.options.items[i].setAttribute('aria-expanded', 'false');
            self.options.items[i].querySelector('.js-accordion-details').ariaHidden = 'true';
            self.animations.toggleAccordionAnimate(self.options.customer, false, self.options.items[i]);
            self.animations.handleNeighbors(self.options.items[i], false, parseInt(self.options.items[i].dataset.index, 10));
          }
        }
      }
      if (self.options.clickCallback) {
        evt.stopImmediatePropagation();
        self.options.clickCallback(self.getItemAttributes(evt), evt);
      }
      self.options.throttled = false;
    }
  }

  handleKeyDown(e) {
    const SPACEBAR_KEY_CODE = 32;
    const ENTER_KEY = 13;
    const ESCAPE_KEY_CODE = 27;
    switch (e.keyCode) {
      case SPACEBAR_KEY_CODE:
      case ENTER_KEY:
        e.preventDefault();
        this.handleChange(e.currentTarget, e);
        return;
      case ESCAPE_KEY_CODE:
        e.preventDefault();
        if (e.target.parentElement.getAttribute('aria-expanded') === 'true') {
          this.handleChange(e.currentTarget, e);
        }
        break;
      default:
    }
  }

  renderItems(options, callback) {
    if (options.el && options !== null && options !== void 0 && options.items.length) {
      const items = [];
      let summary;
      let details;
      for (let i = 0; i < options.items.length; i++) {
        summary = svs.ui.accordion.partials.accordion_summary({
          content: options.items[i].summary
        });
        details = svs.ui.accordion.partials.accordion_details({
          content: options.items[i].details
        });
        items.push(svs.ui.accordion.partials.accordion_item({
          class: options.items[i].class,
          isFlat: options.items[i].isFlat,
          expanded: options.items[i].expanded === undefined ? false : options.items[i].expanded,
          __uniqueId: Math.round(Math.random() * 100000 + 1000 + i),
          content: summary += details
        }));
      }
      const accordion = svs.ui.accordion.partials.accordion({
        class: options.class,
        customer: options.customer,
        branding: options.branding,
        items
      });
      if (options.el instanceof Element) {
        options.el.innerHTML = accordion;
      } else {
        document.querySelector(".".concat(options.el)).innerHTML = accordion;
      }
      callback();
    }
  }

  getItemAttributes(evt) {
    evt.preventDefault();
    const item = {};
    const attributes = evt.currentTarget.attributes;
    for (let i = 0; i < attributes.length; i++) {
      item[attributes[i].name] = attributes[i].value;
    }
    return item;
  }
};

 })(window);